// @flow
import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';

import InvoiceSettingsSaveModal from '../../../../pages/practitioner-portal/MyAccount/invoice_settings_save_modal';
import InvoiceForm from './InvoiceForm';

export const defaultFormState = {
  invoicingEnabled: true,
  invoiceFooter: '',
  bankAccountNumber: '',
  invoiceFrom: {
    name: '',
    floorNumber: '',
    streetAddress: '',
    suburb: '',
    city: '',
    postCode: '',
    logo: '',
    country: '',
  },
  taxNumber: '',
  taxEnabled: false,
  pricesIncludeTax: false,

  // sendPaymentReminders: false,

  // // Automatically send invoice state
  // autoSendNever: true,
  // autoSendInvoiceDays: null,
  // xDaysAfter: 1,

  // // Invoice due date state
  // invoiceDueDate: 14,
  // dueDate2Week: true,

  // // Payment reminder state
  // reminderWhenOverdue: true,
  // paymentRemindersDays: 0,
};

type InvoiceSettingsFormProps = {
  invoices: any;
  onComplete: () => void;
  formState?: any;
  mode?: any;
};

const InvoiceSettingsForm: React.FC<InvoiceSettingsFormProps> = ({ invoices, formState, mode }) => {
  let formRef = React.useRef(null);
  const [saveModalOpen, setSaveModalOpen] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const toggleSaveModalOpen = () => {
    setSaveModalOpen(!saveModalOpen);
  };

  const onSubmit = (values, actions) => {
    setIsSubmitting(true);

    const {
      taxEnabled,
      taxNumber,
      pricesIncludeTax,
      bankAccountNumber,
      daysUntilInvoiceDue,
      invoiceFrom,
      invoiceFooter,
    } = values;

    Object.keys(invoiceFrom).forEach((k) => invoiceFrom[k] == '' && delete invoiceFrom[k]);

    const data = {
      taxEnabled,
      taxNumber,
      pricesIncludeTax,
      bankAccountNumber:
        initialFormState.country === 'AUS' ? bankAccountNumber.replace('-', '') : bankAccountNumber,
      daysUntilInvoiceDue,
      invoiceFrom,
      invoiceFooter,
    };

    invoices.setSettings(data).then((response) => {
      if (!response.hasError) {
        setDisabled(true);
        toast.success('Invoice settings updated');
      } else {
        response.errors.forEach((err) => {
          actions.setFieldTouched(err.field, true, false);
          actions.setFieldError(err.field, err.defaultMessage);
        });
        actions.setStatus({ message: response.message });
        toast.error(response.message);
      }
      actions.setSubmitting(false);
      setIsSubmitting(false);
    });
  };

  const handleSubmitCustom = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }
    setSaveModalOpen(false);
  };

  const initialFormState = {
    ...defaultFormState,
    ...formState,
    invoiceFrom: {
      ...defaultFormState.invoiceFrom,
      ...formState.invoiceFrom,
    },
  };

  return (
    <>
      <div className='items-center flex flex-col'>
        <InvoiceForm
          initialFormState={initialFormState}
          onSubmit={onSubmit}
          formRef={formRef}
          disabled={disabled}
          setDisabled={setDisabled}
          mode={mode}
        />
        <Button
          color='primary'
          size='lg'
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={disabled ? handleSubmitCustom : toggleSaveModalOpen}
          className='mt-4'
        >
          Save
        </Button>
      </div>
      <InvoiceSettingsSaveModal
        isModalOpen={saveModalOpen}
        toggleModal={toggleSaveModalOpen}
        onSubmit={handleSubmitCustom}
        bankAccountNumber={formRef?.current?.values?.bankAccountNumber}
      />
    </>
  );
};

export default withRouter(inject('invoices')(observer(InvoiceSettingsForm)));
