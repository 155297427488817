import React from 'react';
import InputMask from 'react-input-mask';

// Temporary component to wrap InputMask library just in case
// we need to stop using it (not maintained)
// Check old code in PR https://github.com/clearhead-ltd/practitioner-portal/pull/370/files
// for an example of how this can be implemented without library

const GSTField = ({ country, disabled, ...other }: any) => {
  // Set the mask based on country
  let mask = '';
  if (country === 'AUS') {
    mask = '99999999999';
  } else {
    // mask = '999-999-999';
    // NZ tax format can have 2 or 3 digits before first dash so no mask for now
  }

  return (
    <InputMask
      mask={mask}
      className='form-control-lg form-control'
      placeholder={disabled ? '' : 'Enter your GST number'}
      disabled={disabled}
      {...other}
    />
  );
};

export default GSTField;
