import React, { Component } from 'react';
import styled from 'styled-components';

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  CardFooter,
  CardText,
} from 'reactstrap';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import ReactToPrint from 'react-to-print';

import { INVOICE_STATES } from 'configuration';
import InvoiceForm from 'modules/invoices/components/InvoiceForm';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

import currencyFormatter from 'utilities/currency_formatter';
import formatDate from 'utilities/formatDate';

import { Title } from '../styles';

import { ConfirmModal } from 'common';

const CHLogo = styled(CHLogoSvg)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const CHText = styled(CHTextSvg)`
  width: 185px;
  /* height: 80px; */
`;

let InvoiceDocPDF: any;
@inject('invoices', 'auth')
@observer
class InvoiceCard extends Component {
  componentRef = null;

  @observable isEditMode = false;

  @observable dropdownOpen = false;

  @observable confirmModalOpen = false;

  getCurrency = () => {
    const { auth } = this.props;
    return auth.currentPractitioner.country === 'AUS' ? 'AUD' : 'NZD';
  };

  renderItems = () => {
    const { item } = this.props;
    const currency = this.getCurrency();
    if (item.lineItems && item.lineItems.length) {
      return item.lineItems.map((i) => (
        <tr key={`line_${i.description}`}>
          <td>{i.description}</td>
          <td>{currencyFormatter(currency).format(i.amount)}</td>
          <td>{currencyFormatter(currency).format(i.tax)}</td>
          <td className='text-right'>{currencyFormatter(currency).format(i.total)}</td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan={5} align='middle'>
          No items
        </td>
      </tr>
    );
  };

  getTotalPrice = () => {
    const { lineItems } = this.props.item;
    let total = 0;
    if (lineItems && lineItems.length) {
      lineItems.forEach((item) => (total += item.price * item.quantity));
    }
    return total;
  };

  renderTax = () => {
    const total = this.getTotalPrice();
    return total * 0.15;
  };

  renderTotal = () => {
    const { item } = this.props;
    const total = this.getTotalPrice();
    if (item && item.gstNumber && item.gstNumber.length) {
      return total * 1.15;
    }
    return total;
  };

  hasItems = () => {
    const { item } = this.props;
    if (item && item.lineItems && item.lineItems.length) {
      return true;
    }
    return false;
  };

  onSubmit = (response) => {
    const { onUpdate } = this.props;
    this.isEditMode = false;
    onUpdate(response);
  };

  doStatusChange = async (newStatus) => {
    const { item, invoices, onUpdate } = this.props;
    const response = await invoices.setStatus(item.id, newStatus);
    onUpdate(response);
  };

  renderActionButton = () => {
    const { item } = this.props;
    return (
      <div>
        {Object.keys(INVOICE_STATES)
          .filter((key) => this.isStatusChangeAllowed(item, key))
          .map((key) => (
            <Button
              key={key}
              onClick={
                INVOICE_STATES[key].actionLabel === 'Send Invoice'
                  ? () => {
                      this.confirmModalOpen = true;
                    }
                  : () => this.doStatusChange(key)
              }
              className='btn-pill mr-2'
              color={INVOICE_STATES[key].color}
            >
              {`${
                INVOICE_STATES[key].value === item.status
                  ? INVOICE_STATES[key].display + ' - Selected'
                  : INVOICE_STATES[key].actionLabel
              }`}
            </Button>
          ))}
      </div>
    );
  };

  getBillToOption = (billTo) => {
    if (!billTo || !billTo.party) {
      return {
        label: 'Other',
        value: 'OTHER',
      };
    }
    switch (billTo.party) {
      case 'CLEARHEAD':
        return {
          label: 'Clearhead (EAP)',
          value: 'CLEARHEAD',
        };
      case 'CLIENT':
        return {
          label: 'Client',
          value: 'CLIENT',
        };
      default:
        return {
          label: 'Other',
          value: 'OTHER',
        };
    }
  };

  renderInvoice = () => {
    const { item } = this.props;
    if (this.isEditMode) {
      // convert item to form version
      const initialValue = {
        id: item.id,
        invoiceDate: item.invoiceDate,
        billFrom: item.billFrom,
        name: (item.billTo && item.billTo.name) || undefined,
        streetAddress: (item.billTo && item.billTo.streetAddress) || undefined,
        suburb: (item.billTo && item.billTo.suburb) || undefined,
        city: (item.billTo && item.billTo.name) || undefined,
        billToOption: this.getBillToOption(item.billTo),
        dueDate: item.dueDate,
        gstNumber: item.gstNumber,
        items: item.lineItems,
        notes: item.notes && item.notes.length > 0 ? item.notes : undefined,
        client: {
          label: item.client.contactName,
          value: item.client.id,
          email: item.client.email,
        },
      };
      return <InvoiceForm initialValue={initialValue} onSubmit={this.onSubmit} />;
    }
    const { billFrom, billTo, appointment, id } = item;

    const currency = this.getCurrency();

    return (
      <>
        <CardBody>
          <div className='grid grid-cols-2 sm:grid-cols-3 mb-2 text-left'>
            <div>
              <div className='text-muted'>Bill from</div>
              <strong>{billFrom.name}</strong>
              <p className='mb-0'>
                {billFrom.streetAddress || '-'}, <br /> {billFrom.suburb || '-'}
                , <br /> {billFrom.city || '-'} {billFrom.postCode || '-'}
              </p>
            </div>
            <div>
              <div className='mb-2'>
                <div className='text-muted'>Client</div>
                <Link to='#'>
                  <strong>{item.client.contactName}</strong>
                </Link>
              </div>
              {item.billTo ? (
                <div className='mb-2'>
                  <div className='text-muted'>Bill to</div>
                  <p className='mb-0'>
                    <strong>{billTo.name}</strong>
                    <p className='mb-0'>
                      {billTo.streetAddress || '-'}, <br /> {billTo.suburb || '-'}, <br />{' '}
                      {billTo.city || '-'} {billTo.postCode || '-'}
                    </p>
                  </p>
                </div>
              ) : null}
            </div>
            <div className='mt-4 sm:mt-0 mb-8 sm:mb-0'>
              {item.gstNumber && item.gstNumber.length ? (
                <div className='mb-2'>
                  <div className='text-muted'>GST Number</div>
                  <strong>{item.gstNumber}</strong>
                </div>
              ) : null}
              <div className='mb-2'>
                <div className='text-muted'>Invoice Date</div>
                <strong>{formatDate(item.invoiceDate)}</strong>
              </div>
              <div className='mb-2'>
                <div className='text-muted'>Due Date</div>
                <strong>{formatDate(item.dueDate)}</strong>
              </div>
            </div>
          </div>
          <div className='overflow-x-scroll'>
            <Table size='sm' className='mb-6'>
              <thead>
                <tr>
                  <th style={{ width: '50%' }}>Description</th>
                  <th style={{ width: '20%' }}>Amount</th>
                  <th style={{ width: '10%' }}>Tax</th>
                  <th style={{ width: '20%' }} className='text-right'>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderItems()}
                {item.gstNumber && item.gstNumber.length ? (
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Tax</td>
                    <td className='text-right'>{currencyFormatter(currency).format(item.tax)}</td>
                  </tr>
                ) : null}
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>Total</td>
                  <td className='text-right'>{currencyFormatter(currency).format(item.total)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          {item.notes && <p className='text-center mb-6'>{item.notes}</p>}
          <div className='hidden print:block'>
            <p className='text-center mb-0 text-muted' style={{ fontSize: '0.8rem' }}>
              POWERED BY
            </p>
            <Row form className='mb-5'>
              <Col className='d-flex justify-content-center'>
                <CHLogo />
                <CHText />
              </Col>
            </Row>
          </div>
        </CardBody>
        {item.status !== 'CANCELLED' && (
          <CardFooter className='d-flex align-items-center justify-content-between print:hidden'>
            {/*Empty div to push action buttons to the right while Send Reminder is commented out */}
            <div></div>
            {/* TODO We will need to add this back, probably as a button instead
            {item.status === 'OVERDUE' ? ( //TODO: replace onClick
              <button onClick={console.log('Send Reminder')}>
                <Text className='text-primary-blue-100 font-bold cursor-pointer text-sm hover:underline'>
                  Send Reminder
                </Text>
              </button>
            )} : <div></div> */}
            {item.status === 'SENT' ? (
              <ReactToPrint
                trigger={() => (
                  <Button
                    color='info'
                    className='btn-pill mr-2 flex items-center print:hidden'
                    onClick={() => onEdit()}
                    outline
                  >
                    <Icon.Printer size={14} className='mr-1' /> PRINT
                  </Button>
                )}
                content={() => this.componentRef}
              />
            ) : (
              this.renderActionButton()
            )}
          </CardFooter>
        )}
        <ConfirmModal
          isOpen={this.confirmModalOpen}
          onConfirm={() => {
            this.doStatusChange('SENT');
            this.confirmModalOpen = false;
          }}
          toggleModal={() => (this.confirmModalOpen = !this.confirmModalOpen)}
          onCancel={() => {
            this.confirmModalOpen = false;
          }}
          title={<CardHeader>Confirm Invoice</CardHeader>}
          body={
            <CardText>
              Once you send the invoice, the details can no longer be changed. Please double-check
              that everything is correct before sending it.
            </CardText>
          }
        />
      </>
    );
  };

  isStatusChangeAllowed(item, toStatus) {
    if (item.billTo.party === 'CLEARHEAD') {
      switch (item.status) {
        case 'DRAFT':
          switch (toStatus) {
            case 'DRAFT':
              return false;
            case 'SENT':
            case 'CANCELLED':
              return true;
            case 'OVERDUE':
            case 'PAID':
              return false;
          }
        case 'SENT':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
        case 'PAID':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
        case 'CANCELLED':
          return false;
        case 'OVERDUE':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
      }
    } else {
      switch (item.status) {
        case 'DRAFT':
          switch (toStatus) {
            case 'DRAFT':
              return false;
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
              return true;
            case 'OVERDUE':
              return false;
          }
        case 'SENT':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
              return false;
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return true;
          }
        case 'PAID':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
        case 'CANCELLED':
          return false;
        case 'OVERDUE':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
              return false;
            case 'CANCELLED':
            case 'OVERDUE':
              return true;
          }
      }
    }
    return false;
  }

  renderTopBarActions = () => {
    const { item, onEdit } = this.props;
    if (this.isEditMode) {
      return (
        <div>
          <Button
            color='warning'
            className='btn-pill'
            onClick={() => (this.isEditMode = false)}
            outline
          >
            <Icon.Trash2 size={14} /> CANCEL
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          {item.status === 'DRAFT' && item.billTo.party !== 'CLEARHEAD' && false ? ( // Disable this for now
            <Button
              color='warning'
              className='btn-pill'
              onClick={() => {
                onEdit();
                this.isEditMode = true;
              }}
              outline
            >
              <Icon.Edit2 size={14} /> EDIT
            </Button>
          ) : null}
        </div>
      );
    }
  };

  render() {
    const { item, isOpen = false, toggle } = this.props;
    return (
      <Card className='mb-1' innerRef={(el) => (this.componentRef = el)}>
        <CardHeader className='d-flex justify-content-between'>
          <div className='d-flex justify-content-start align-items-center'>
            <Title
              onClick={toggle}
              className='h3 mb-0 mr-3 text-primary'
            >{`Invoice #${`${item.id}`.padStart(5, '0')}`}</Title>
            <Button color={INVOICE_STATES[item.status].color} size='sm' className='btn-pill'>
              {INVOICE_STATES[item.status].display}
            </Button>
            {/* <InvoiceStatusSelect
              id={item.id}
              isOpen={this.dropdownOpen}
              toggle={() => (this.dropdownOpen = !this.dropdownOpen)}
              onSelect={(val) => console.log(val)}
              value={item.status}
            /> */}
          </div>
          {this.renderTopBarActions()}
        </CardHeader>
        {isOpen && this.renderInvoice()}
      </Card>
    );
  }
}

export default InvoiceCard;

// TODO: Only need the below check for public invoices
{
  /* <div className='flex flex-col items-start'>
    {!this.hasItems() && (
      <p className='m-0 text-danger'>
        Please add a line item before you send your invoice.
      </p>
    )}
  </div> */
}
