import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Loading } from 'common';
import { toast } from 'react-toastify';
import Switch from 'common/Switch';
import InvoiceSettingsForm from 'modules/auth/components/InvoiceSettingsForm';
import HelpLink from '../Invoice/HelpLink';

@inject('invoices', 'auth')
@observer
class InvoiceSection extends Component {
  @observable switchDisabled = false;
  @observable loading = true;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.invoices.getSettings().then((resp) => {
      this.loading = false;
    });
  }

  toggleEnableInvoices = () => {
    const { auth } = this.props;

    if (!this.switchDisabled) {
      const data = {
        id: auth.currentPractitioner.id,
        invoicingEnabled: !auth.currentPractitioner.invoicingEnabled,
      };

      this.switchDisabled = true;

      auth.updatePractitioner(data).then((response) => {
        if (!response.hasError) {
          toast.success(
            `Invoicing ${auth.currentPractitioner.invoicingEnabled ? 'enabled' : 'disabled'}`
          );
        } else {
          toast.error(response.message);
        }
        this.switchDisabled = false;
      });
    }
  };

  render() {
    const {
      invoices: { settings },
      auth: { currentPractitioner },
    } = this.props;

    if (this.loading) {
      return <Loading />;
    }

    const {
      taxEnabled,
      taxNumber,
      pricesIncludeTax,
      bankAccountNumber,
      daysUntilInvoiceDue,
      invoiceFrom,
      invoiceFooter,
    } = settings || {};

    Object.keys(invoiceFrom).forEach((k) => invoiceFrom[k] == null && delete invoiceFrom[k]);

    const formState = {
      ...{ taxEnabled },
      ...(taxEnabled && taxNumber && { taxNumber }),
      ...{ pricesIncludeTax },
      ...(bankAccountNumber && { bankAccountNumber }),
      ...(daysUntilInvoiceDue && { daysUntilInvoiceDue }),
      ...(invoiceFooter && { invoiceFooter }),
      ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 7 && { dueDate1Week: true }),
      ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 14 && { dueDate2Week: true }),
      ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 21 && { dueDate3Week: true }),
      ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 28 && { dueDate4Week: true }),
      invoiceFrom,
      country: this.props.auth.currentPractitioner.country,
    };

    return (
      <>
        {this.props.auth.currentPractitioner.country == 'AUS' && (
          <Card className='mb-2'>
            <CardBody className='flex justify-between items-center'>
              <Switch
                checked={currentPractitioner.invoicingEnabled}
                onChange={this.toggleEnableInvoices}
                disabled={this.switchDisabled}
              >
                Enable Invoices
              </Switch>
            </CardBody>
          </Card>
        )}

        {this.props.auth.currentPractitioner.invoicingEnabled && (
          <Card className='mb-2 p-2'>
            <CardHeader className='d-flex justify-content-between align-items-center'>
              <CardTitle tag='h5' className='mb-0'>
                Invoice Settings
              </CardTitle>
              <HelpLink variant='settings' />
            </CardHeader>
            <CardBody>
              <InvoiceSettingsForm formState={formState} />
            </CardBody>
          </Card>
        )}
      </>
    );
  }
}

export default InvoiceSection;
