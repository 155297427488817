'use client';
import * as React from 'react';
import {
  Popover as HeadlessPopover,
  PopoverPanel,
  PopoverButton,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import Text from '../Text';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';

export type PopoverProps = {
  popoverLocation?: AnchorProps;
  text?: string;
  className?: string;
  buttonClassName?: string;
  popoverClassName?: string;
  CustomButton?: React.ReactNode;
  CustomPopover?: React.ReactNode;
};

const Popover: React.FC<PopoverProps> = ({
  popoverLocation,
  text,
  className,
  buttonClassName,
  popoverClassName,
  CustomButton,
  CustomPopover,
}) => {
  return (
    <HeadlessPopover className={clsx('relative h-[35px]', className)}>
      {({ open }) => (
        <>
          <PopoverButton
            className={clsx(
              'appearance-none outline-none cursor-pointer p-0 m-0 border-0 bg-transparent',
              buttonClassName
            )}
          >
            {CustomButton ?? <span>open</span>}
          </PopoverButton>
          <Transition
            as={React.Fragment}
            show={open}
            enter='transition duration-200 ease-out'
            enterFrom='opacity-0 -translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition duration-150 ease-in'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 -translate-y-1'
          >
            <PopoverPanel
              className={clsx(
                'bg-transparent text-center z-10 border border-solid border-blue rounded min-w-fit',
                popoverClassName
              )}
              anchor={popoverLocation}
            >
              {CustomPopover ?? <Text>{text}</Text>}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </HeadlessPopover>
  );
};

export default Popover;
