import React, { Component } from 'react';
import { Button, Container, Card, Col, InputGroup, Row, Table, Nav } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { reaction, observable, action } from 'mobx';
import { DateRangePicker } from 'react-dates';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import moment from 'moment';

import { Loading } from 'common';
import ClientService from 'services/ClientService';
import InvoiceService from 'services/InvoiceService';
import {
  FilterButton,
  FilterContainer,
  FilterButtonsContainer,
  FilterButtonContainer,
  FilterCount,
} from 'styles/filters';

import './override_dates.css';
import InvoiceLineItem from './InvoiceLineItem';
import SelectedClient from 'pages/practitioner-portal/Clients/selected_client';
import { HeaderContainer, ColumnHeader, Filters, TableContainer } from './styles';
import { toast } from 'react-toastify';
import { errorMessage } from '../Booking/Complete';

let UNPAID_STATUSES = ['SENT', 'OVERDUE'];
let DRAFT_STATUSES = ['DRAFT'];

@inject('invoices', 'auth')
@observer
class Invoices extends Component {
  @observable loading = true;

  @observable filterStatusLoading = true;

  @observable selectedClient = null;

  @observable selectedStatuses = DRAFT_STATUSES;

  @observable startDate = moment('2023-01-01', 'YYYY-MM-DD');

  @observable endDate = moment().endOf('month');

  @observable minDate = moment().subtract(2, 'years');

  @observable maxDate = moment();

  @observable focusedInput = null;

  @observable draftTotal = 0;

  @observable unpaidTotal = 0;

  @action
  setLoadingState = (newState) => {
    this.loading = newState;
  };

  @action
  setSelectedClient = (client) => {
    this.selectedClient = client;
  };

  constructor(props) {
    super(props);
    reaction(
      () => this.startDate,
      (date) => {
        this.fetchAll();
      }
    );
    reaction(
      () => this.endDate,
      (date) => {
        this.fetchAll();
      }
    );
    reaction(
      () => this.selectedStatuses.map((text) => text),
      (statuses) => {
        this.fetchAll();
      }
    );
  }

  componentDidMount = async () => {
    await this.fetchAll();
    await this.getStatusTotals();
  };

  getCurrency = () => {
    const { auth } = this.props;
    return auth.currentPractitioner.country === 'AUS' ? 'AUD' : 'NZD';
  };

  getStatusTotals = async () => {
    this.filterStatusLoading = true;
    const fromDate = moment(this.startDate).format('YYYY-MM-DD');
    const toDate = moment(this.endDate).format('YYYY-MM-DD');
    const response = await InvoiceService.search({
      fromDate,
      toDate,
      statuses: ['SENT', 'OVERDUE', 'DRAFT'],
      pageOffset: 0,
      pageSize: 500,
    });
    if (
      !response.hasError &&
      response.data &&
      response.data.content &&
      response.data.content.length > 0
    ) {
      let unpaidCount = 0;
      let draftCount = 0;
      response.data.content.forEach((d: any) => {
        if (UNPAID_STATUSES.indexOf(d.status) > -1) {
          unpaidCount += 1;
        }
        if (d.status === 'DRAFT') {
          draftCount += 1;
        }
      });
      this.draftTotal = draftCount;
      this.unpaidTotal = unpaidCount;
    }
    this.filterStatusLoading = false;
  };

  fetchAll = async () => {
    this.loading = true;
    const { invoices } = this.props;
    const data = {
      fromDate: moment(this.startDate).format('YYYY-MM-DD'),
      toDate: moment(this.endDate).format('YYYY-MM-DD'),
      statuses: this.selectedStatuses,
      pageOffset: 0,
      pageSize: 500,
    };
    const response = await invoices.search(data);
    if (response.hasError) {
      // show error
      console.error(response);
      toast.error(errorMessage);
    }
    this.loading = false;
  };

  onClientSelect = async (id) => {
    const response = await ClientService.getClientDetails(id);
    if (!response.hasError) {
      this.setSelectedClient(response.data.responseObject);
    } else {
      this.setSelectedClient(null);
    }
  };

  renderItems = (items = [], showDueDate) => {
    if (items && items.length) {
      return items.map((item) => (
        <InvoiceLineItem
          key={`${item.id}`}
          item={item}
          currency={this.getCurrency()}
          onClientClick={() => this.onClientSelect(item.client.id)}
          showDueDate={showDueDate}
        />
      ));
    }
    return (
      <tr>
        <td colSpan={0}>
          <span>No invoices found</span>
        </td>
      </tr>
    );
  };

  renderTable = (items = []) => {
    const showDueDate = this.selectedStatuses.indexOf('SENT') > -1;
    return (
      <Card>
        <TableContainer>
          <Table>
            <thead>
              <tr className='border-b-2 border-primary-blue-15'>
                <th scope='col'>
                  <ColumnHeader className='text-black'>Status</ColumnHeader>
                </th>
                {showDueDate && (
                  <th scope='col'>
                    <ColumnHeader className='text-black'>Due Date</ColumnHeader>
                  </th>
                )}
                <th scope='col' className='hidden sm:table-cell'>
                  <ColumnHeader className='text-black'>Created</ColumnHeader>
                </th>
                <th scope='col'>
                  <ColumnHeader className='text-black'>Client</ColumnHeader>
                </th>
                <th scope='col'>
                  <ColumnHeader className='text-black'>Amount Due</ColumnHeader>
                </th>
                {/* <th scope="col">
                <ColumnHeader className="text-black">APPOINTMENT</ColumnHeader>
              </th>*/}
                <th scope='col' className='hidden sm:table-cell'>
                  <ColumnHeader className='text-black'>Invoice ID</ColumnHeader>
                </th>

                {/* <th scope="col"></th> */}
              </tr>
            </thead>
            <tbody>{this.renderItems(items, showDueDate)}</tbody>
          </Table>
        </TableContainer>
      </Card>
    );
  };

  setStatus = (status) => {
    // this.selectedStatuses.splice(0, this.selectedStatuses.length);

    this.selectedStatuses = status;
    // .push(status);
    // const index = this.selectedStatuses.indexOf(status);
    // if (index > -1) {
    //   this.selectedStatuses.splice(index, 1);
    // } else {
    //   this.selectedStatuses.push(status);
    // }
  };

  isActiveFilter = (values: string[]) => {
    return values.every((x) => this.selectedStatuses.some((y) => y === x));
  };

  renderTabs = (values) => (
    <>
      <FilterContainer className='d-flex justify-content-center align-items-center'>
        <FilterButtonsContainer>
          <FilterButtonContainer>
            <FilterButton
              className={`${this.isActiveFilter(DRAFT_STATUSES) ? 'is-active' : ''} text-dark-blue`}
              onClick={() => this.setStatus(DRAFT_STATUSES)}
            >
              Draft <FilterCount>{this.draftTotal}</FilterCount>
            </FilterButton>
          </FilterButtonContainer>
          <FilterButtonContainer>
            <FilterButton
              className={`${
                this.isActiveFilter(UNPAID_STATUSES) ? 'is-active' : ''
              } text-dark-blue`}
              onClick={() => this.setStatus(UNPAID_STATUSES)}
            >
              Unpaid <FilterCount>{this.unpaidTotal}</FilterCount>
            </FilterButton>
          </FilterButtonContainer>
          <FilterButtonContainer>
            <FilterButton
              className={`${this.selectedStatuses.length === 0 ? 'is-active' : ''} text-dark-blue`}
              onClick={() => this.setStatus([])}
            >
              All Invoices
            </FilterButton>
          </FilterButtonContainer>
        </FilterButtonsContainer>
      </FilterContainer>
      {this.loading ? <Loading /> : this.renderTable(values)}
    </>
  );

  render() {
    const { invoices } = this.props;
    const values = Array.from(invoices.all.values());
    values.sort((a, b) => new Date(b.creationDateTime) - new Date(a.creationDateTime));
    return (
      <Container fluid>
        <Row>
          <Col sm='12' md={this.selectedClient ? '8' : '12'} lg={this.selectedClient ? '8' : '12'}>
            <HeaderContainer className='mb-3'>
              <div className='d-flex justify-content-start align-items-center'>
                <Heading className='text-xl text-dark-blue'>Invoices</Heading>
              </div>
            </HeaderContainer>
            {this.renderTabs(values)}
          </Col>
          {this.selectedClient && (
            <Col sm='12' md={this.selectedClient ? '4' : '0'} lg={this.selectedClient ? '4' : '0'}>
              <SelectedClient
                client={this.selectedClient}
                editClient={() => {}}
                deleteClient={() => {}}
                appointmentsLoading={false}
                selectBooking={() => {}}
                hideDropdown={true}
              />
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default Invoices;

{
  /* <Filters> */
}
// <InputGroup>
{
  /* <InputGroupAddon addonType="prepend">From</InputGroupAddon> */
}
{
  /* <DateRangePicker
              startDate={this.startDate}
              startDateId="your_unique_start_date_id"
              endDate={this.endDate}
              minDate={this.minDate}
              maxDate={this.maxDate}
              endDateId="your_unique_end_date_id"
              displayFormat="DD/MM/YY"
              small
              isOutsideRange={() => false}
              onDatesChange={({ startDate, endDate }) => {
                this.startDate = startDate;
                this.endDate = endDate;
              }}
              focusedInput={this.focusedInput}
              onFocusChange={(focusedInput) =>
                (this.focusedInput = focusedInput)
              }
            /> */
}
// </InputGroup>
// </Filters>
