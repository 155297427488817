import React from 'react';
import { inject, observer } from 'mobx-react';
import { createWizardFormContext } from '../../../common/useWizard';
import { getFooter } from './WizardForm/index';
import WizardForm from './WizardForm';
import ReOnboardingIntroStep from './ReOnboardingIntroStep/index';
import ProfileSetupIntroStep from './ProfileSetupIntroStep/index';
import ProfileImageStep from './ProfileImageStep/index';
import ProfileBioStep from './ProfileBioStep/index';
import AddServicesStep from './AddServicesStep/index';
import AddLocationsStep from './AddLocationsStep/index';
import AvailabilityStep from './AvailabilityStep';
import InvoicingStep from './InvoicingStep';
import ProfessionalInfoStep from './ProfessionalInfoStep';

import { Loading } from 'common';

import CalendarSyncStep from './CalendarSyncStep';
import FinalPageStep from './FinalPageStep';
import TagsStep from './TagsStep/index';
import OtherInformationStep from './OtherInformationStep/index';
import CalendarSyncService from '../../../services/CalendarSyncService';

export const WizardFormContext = createWizardFormContext();

const defaultSteps = [
  ReOnboardingIntroStep,
  ProfileSetupIntroStep,
  ProfileImageStep,
  ProfessionalInfoStep,
  TagsStep,
  OtherInformationStep,
  ProfileBioStep,
  AddServicesStep,
  AddLocationsStep,
  AvailabilityStep,
  InvoicingStep,
  CalendarSyncStep,
  FinalPageStep,
];

const OnboardingForm = inject(
  'auth',
  'prices',
  'locations',
  'schedule',
  'calendarSync',
  'invoices'
)(
  observer((props) => {
    const [initialized, setInitialized] = React.useState(false);
    const [calSync, setCalSync] = React.useState(null);
    const [filteredSteps, setFilteredSteps] = React.useState();

    // Hack to fix hack of modal opening a modal not scolling
    React.useEffect(() => {
      document.body.setAttribute('style', 'overflow: auto !important');
      return () => {
        document.body.setAttribute('style', '');
      };
    }, []);

    React.useEffect(() => {
      if (!initialized) {
        return;
      }

      const {
        auth: { userImage, currentPractitioner },
      } = props;

      const langSize = currentPractitioner.languages?.length ?? 0;
      const ethnicitySize = currentPractitioner.ethnicity?.length ?? 0;
      const hasGender = currentPractitioner.gender;

      let tempSteps = [...defaultSteps];

      if (!currentPractitioner.onboarded || currentPractitioner.lastOnboarded) {
        // Remove steps if new user
        tempSteps.splice(tempSteps.indexOf(ReOnboardingIntroStep), 1);
        tempSteps.splice(tempSteps.indexOf(ProfessionalInfoStep), 1);
      }
      if (userImage && !userImage.includes('avataaars')) {
        tempSteps.splice(tempSteps.indexOf(ProfileImageStep), 1);
      }

      // If the users have been through the steps since we added the lastOnboarding flag then dont make them do it again
      if (currentPractitioner.lastOnboarded) {
        if (currentPractitioner.fullBiography) {
          tempSteps.splice(tempSteps.indexOf(ProfileBioStep), 1);
        }
        if (props.auth.currentPractitioner.tags.length) {
          tempSteps.splice(tempSteps.indexOf(TagsStep), 1);
        }
        if (langSize > 0 && ethnicitySize > 0 && hasGender) {
          tempSteps.splice(tempSteps.indexOf(OtherInformationStep), 1);
        }
      }
      if (props.auth.currentPractitioner.paymentOptions.length) {
        tempSteps.splice(tempSteps.indexOf(AddServicesStep), 1);
      }
      if (props.auth.currentPractitioner.serviceLocations.length) {
        tempSteps.splice(tempSteps.indexOf(AddLocationsStep), 1);
      }
      if (props.schedule.schedule && props.schedule.schedule.template.length > 0) {
        tempSteps.splice(tempSteps.indexOf(AvailabilityStep), 1);
      }
      if (props.auth.currentPractitioner.invoicingSetup || !currentPractitioner.invoicingEnabled) {
        tempSteps.splice(tempSteps.indexOf(InvoicingStep), 1);
      }
      if (
        (props.auth.currentPractitioner.calendarUsed || calSync) &&
        tempSteps.indexOf(CalendarSyncStep)
      ) {
        tempSteps.splice(tempSteps.indexOf(CalendarSyncStep), 1);
      }

      setFilteredSteps(tempSteps);
    }, [
      initialized,
      calSync,
      props.auth.currentPractitioner,
      props.schedule.schedule,
      props.calendarSync.syncedCalendars,
    ]);

    React.useEffect(() => {
      (async () => {
        if (props.auth.currentPractitioner && props.schedule && !initialized) {
          await props.schedule.getSchedule();

          if (props.auth.currentPractitioner.calendarUsed) {
            setCalSync(true);
          } else {
            const res = await CalendarSyncService.getSyncedCalendars();
            if (res.data && res.data.length >= 1) {
              setCalSync(true);
            } else {
              setCalSync(false);
            }
          }
          setInitialized(true);
        }
      })();
    }, [
      props.auth.currentPractitioner,
      props.schedule.schedule,
      props.calendarSync.syncedCalendars,
    ]);

    if (!initialized || !filteredSteps) return <Loading />;
    return (
      <div className='text-center -m-4'>
        {props.auth.currentPractitioner &&
        props.schedule.schedule &&
        filteredSteps &&
        calSync !== null ? (
          <WizardForm
            initialData={{}}
            initialStep={0}
            steps={filteredSteps}
            customFooter={getFooter}
            useFooterFor={filteredSteps.length - 1}
          />
        ) : null}
      </div>
    );
  })
);
export default OnboardingForm;
