import * as React from 'react';
import { observer, inject } from 'mobx-react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Formik } from 'formik';

import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Input from '@clearhead-ltd/ui-components/dist/v2/Form/Input';
import Modal from '@clearhead-ltd/ui-components/dist/v2/Modal';
import OptionLabel from '@clearhead-ltd/ui-components/dist/v2/OptionLabel';
import { Add } from '@clearhead-ltd/ui-components/dist/v2/Icons';
import { BlockLoading } from '../../../common/Loading';
import pencilIMG from '../../../assets/img/pencil.png';
import { OptionCard } from '../SetupWizard/WizardForm/styles';

import { BinIcon } from '../ManageAvailability/styles';
import { toast } from 'react-toastify';

const PlusIcon = styled(Add)`
  height: 18px;
  width: 18px;
  path {
    stroke: #4987ce;
    fill: #4987ce;
    stroke-width: 2;
  }
`;

const timeArray = [15, 30, 45, 60, 75, 90, 105, 120];

export const convertGstInclToExcl = (cost, country) => {
  switch (country) {
    case 'AUS':
      return cost / 1.1;
    case 'CAN':
      return cost / 1.05;
    default:
      return cost / 1.15;
  }
};

const ServicesComponent = inject(
  'prices',
  'app',
  'auth',
  'invoices'
)(
  observer((props) => {
    const [editingModal, setEditingModal] = React.useState(false);
    const [serviceModalOpen, setServiceModalOpen] = React.useState(false);
    const [serviceToEdit, setServiceToEdit] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [serviceArray, setServiceArray] = React.useState([]);
    const [pricesIncludeTax, setPricesIncludeTax] = React.useState(undefined);
    const eapPriceLimit = 350;

    const fetchServices = async () => {
      const services = await props.prices.getPrices();
      setServiceArray(services.data.responseObject);
    };

    const createService = async (data) => {
      const newService = {
        cost: `${data.sessionCost}`,
        name: `${data.serviceName}`,
        sessionLength: `${data.sessionLength}`,
        hasCriteria: `${data.subsidised}`,
        criteria: data.subsidised ? data.conditions : '',
      };
      props.prices.createPrice(newService).then((e) => {
        useToast(e.data, 'Service Created', 'Creating Service Failed');
      });
    };

    const editService = async (data) => {
      const updatedService = {
        id: `${serviceToEdit.id}`,
        bookingLength: `${serviceToEdit.bookingLength}`,
        defaultPrice: `${serviceToEdit.defaultPrice}`,
        description: `${serviceToEdit.description}`,
        name: `${data.serviceName}`,
        sessionLength: `${Math.round(data.sessionLength / 15) * 15}`,
        cost: `${data.sessionCost}`,
        hasCriteria: `${data.subsidised}`,
        criteria: data.subsidised ? data.conditions : '',
      };
      props.prices.editPrice(serviceToEdit, updatedService).then((e) => {
        useToast(e.data, 'Service Updated', 'Updating Service Failed');
      });
    };

    const editServiceModal = (id) => {
      setServiceToEdit(
        serviceArray.find((e) => {
          return e.id === id;
        })
      );
    };

    const getCurrencyOption = (country) => {
      switch (country) {
        case 'AUS':
          return 'AUD';
        case 'CAN':
          return 'CAD';
        default:
          return 'NZD';
      }
    };

    const removeService = (data) => {
      props.prices.deletePrice(data).then((e) => {
        useToast(e.data, 'Service Removed', 'Service Removal Failed');
      });
    };

    const useToast = (data, successText, failText) => {
      if (data) {
        toast.success(successText);
        fetchServices();
      } else {
        toast.error(failText);
      }
    };

    const schema = Yup.object().shape({
      serviceName: Yup.string()
        .min(5, 'Please provide a descriptive name for the service')
        .max(50, 'The name cannot be longer than 50 characters')
        .required('Please enter a service name'),
      sessionLength: Yup.number()
        .typeError('Session Length must be a number')
        .max(12 * 60, 'Maximum of 12 hours')
        .required('Required'),
      sessionCost: Yup.number()
        .typeError('Session Cost must be a number')
        .min(0, 'Session Cost must be more than 0')
        .max(9999999, 'Session Cost cannot be more than 9999999')
        .required('Required'),
      conditions: Yup.string().when('subsidised', {
        is: true,
        then: Yup.string()
          .max(50, 'Conditions cannot be longer than 50 characters')
          .required('Please enter your conditions'),
      }),
    });

    React.useEffect(() => {
      if (props.setIsValid) {
        props.setIsValid(serviceArray.length > 0);
      }
    }, [serviceArray]);

    React.useEffect(() => {
      (async () => {
        await fetchServices();
        const invoiceSettings = await props.invoices.getSettings();
        if (invoiceSettings.data) {
          setPricesIncludeTax(invoiceSettings.data.pricesIncludeTax);
        }
        setIsLoading(false);
      })();
    }, []);

    const priceOverLimit = (cost) => {
      let price;
      if (pricesIncludeTax) {
        price = convertGstInclToExcl(
          cost,
          props.auth.currentPractitioner && props.auth.currentPractitioner.country
            ? props.auth.currentPractitioner.country
            : 'NZL'
        );
      } else {
        price = cost;
      }

      return price > eapPriceLimit;
    };

    return (
      <div className='mt-8 w-full'>
        <div className='text-center w-full'>
          <Heading className='text-dark-blue mb-6' as={'h3'}>
            Add your services
          </Heading>
          {isLoading ? <BlockLoading /> : null}
          {serviceArray.map((service) => (
            <div key={service.id} className='flex mb-6 px-6 w-full'>
              <OptionCard className='w-full text-left min-h-20'>
                <div className='flex'>
                  <Heading className='text-dark-blue' as={'h5'}>
                    {service.name}
                  </Heading>
                  <Heading className='text-dark-blue ml-auto' as={'h5'}>
                    ${service.cost}
                  </Heading>
                </div>
                <div className='flex'>
                  <Text className='text-sm'>
                    {service.sessionLength &&
                      `${Math.round(service.sessionLength / 15) * 15} minutes`}
                  </Text>
                  <Text className='text-sm ml-auto'>
                    {pricesIncludeTax == undefined ? '' : pricesIncludeTax ? 'inc GST' : 'excl GST'}
                  </Text>
                </div>
              </OptionCard>
              <div>
                <Button
                  variant='none'
                  modifiers='naked'
                  className='ml-2 mt-2  outline-none'
                  onClick={() => {
                    editServiceModal(service.id);
                    setEditingModal(true);
                    setServiceModalOpen(true);
                  }}
                >
                  <img alt='pencil' className='h-5' src={pencilIMG} />
                </Button>
                <Button
                  variant='none'
                  modifiers='naked'
                  className='ml-2 mt-3'
                  onClick={() => {
                    removeService(service);
                  }}
                >
                  <BinIcon />
                </Button>
              </div>
            </div>
          ))}
          <Button
            variant='none'
            modifiers='naked'
            className='w-full px-6'
            onClick={() => {
              setServiceModalOpen(true);
              setEditingModal(false);
            }}
          >
            <OptionLabel className='bg-primary-blue-15 w-full hover:bg-primary-blue-50 h-14'>
              <div className='flex mr-auto w-full items-vertical'>
                <PlusIcon />
                <Heading className='text-dark-blue ml-2' as={'h5'}>
                  Add a Service
                </Heading>
              </div>
            </OptionLabel>
          </Button>
          {serviceModalOpen ? (
            <Modal
              className='min-h-min'
              isOpen={serviceModalOpen}
              hideModal={() => setServiceModalOpen(false)}
            >
              <Formik
                initialValues={
                  editingModal
                    ? {
                        serviceName: serviceToEdit.name,
                        sessionLength: serviceToEdit.sessionLength,
                        sessionCost: serviceToEdit.cost,
                        subsidised: serviceToEdit.hasCriteria,
                        conditions: serviceToEdit.criteria,
                      }
                    : {
                        serviceName: '',
                        sessionLength: 60,
                        sessionCost: '',
                        subsidised: false,
                        conditions: '',
                      }
                }
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values) => {
                  editingModal ? editService(values) : createService(values);
                  setEditingModal(false);
                  setServiceModalOpen(false);
                }}
              >
                {({ values, touched, errors, handleSubmit, setFieldValue }) => {
                  return (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className='p-4'>
                        <div className='w-full mb-2'>
                          <div>
                            <Heading className='text-dark-blue text-center mb-4' as='h4'>
                              Add a Service
                            </Heading>
                          </div>
                          <div className='mb-3'>
                            <Heading className='text-dark-blue' as='h5'>
                              Service Name
                            </Heading>
                            <Input
                              type='text'
                              name='serviceName'
                              className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                              maxLength={50}
                              placeholder='Service Name'
                              value={values.serviceName}
                              invalid={touched.serviceName && !!errors.serviceName}
                              onChange={(e) => {
                                setFieldValue('serviceName', e.target.value);
                              }}
                              onBlur={(e) => {
                                setFieldValue('serviceName', e.target.value.trim());
                              }}
                            />
                            {errors.serviceName && (
                              <div className='text-red text-base'>{errors.serviceName}</div>
                            )}
                          </div>
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3'>
                          <div className='col-span-1'>
                            <Heading className='text-dark-blue' as='h5'>
                              Session Length
                            </Heading>
                            <div className='h-12 relative rounded-md shadow-sm'>
                              <select
                                id='sessionLength'
                                name='sessionLength'
                                className='border-2 border-primary-blue-50 bg-transparent h-12 block w-full pl-2 pr-16 sm:text-sm rounded-md mt-2'
                                onChange={(e) => {
                                  setFieldValue('sessionLength', e.target.value);
                                }}
                                defaultValue={Math.round(values?.sessionLength / 15) * 15}
                              >
                                {timeArray.map((t) => {
                                  return (
                                    <option key={t} value={t}>
                                      {t}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className='absolute inset-y-0 right-6 flex items-center'>
                                <span className='text-gray-500 sm:text-sm'>minutes</span>
                              </div>
                            </div>
                            {errors.sessionLength && (
                              <div className='text-red text-base'>{errors.sessionLength}</div>
                            )}
                          </div>
                          <div className='col-span-1'>
                            <Heading className='text-dark-blue' as='h5'>
                              Session Cost
                            </Heading>
                            <>
                              <div className='mt-2'>
                                <div className='h-12 mt-1 relative rounded-md shadow-sm'>
                                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                    <span className='text-gray-500 sm:text-sm font-bold'>$</span>
                                  </div>
                                  <Input
                                    type='text'
                                    name='sessionCost'
                                    id='price'
                                    className='border-2 border-primary-blue-50 h-12 block w-full pl-6 pr-16 sm:text-sm rounded-md'
                                    placeholder='0.00'
                                    value={values.sessionCost}
                                    invalid={touched.sessionCost && !!errors.sessionCost}
                                    onChange={(e) => {
                                      var regex = /^\d*(?:\.?\d{0,2})$/;
                                      if (regex.test(e.target.value)) {
                                        setFieldValue('sessionCost', e.target.value);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      setFieldValue('sessionCost', e.target.value.trim());
                                    }}
                                  />
                                  <div className='absolute inset-y-0 right-0 flex items-center'>
                                    <label htmlFor='currency' className='sr-only'>
                                      Currency
                                    </label>
                                    <select
                                      id='currency'
                                      name='currency'
                                      className='h-full outline-none py-0 pl-2 pr-2 mr-2 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md'
                                    >
                                      <option>
                                        {getCurrencyOption(
                                          props.auth.currentPractitioner &&
                                            props.auth.currentPractitioner.country
                                            ? props.auth.currentPractitioner.country
                                            : 'NZL'
                                        )}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </>
                            {errors.sessionCost && (
                              <div className='text-red text-base'>{errors.sessionCost}</div>
                            )}
                          </div>
                        </div>
                        {priceOverLimit(values.sessionCost) && (
                          <div className='text-red text-sm mb-4 -mt-1'>
                            {`Please note that EAP clients won't be able to book this service as this is above the $${eapPriceLimit} limit${
                              pricesIncludeTax ? ' (Your prices include GST)' : ''
                            }. Public users will still be able to book this service.`}
                          </div>
                        )}
                        {editingModal && serviceToEdit.cost !== values.sessionCost && (
                          <Text className='text-sm -mt-2 w-ful text-danger'>
                            Please note that any bookings already confirmed for this service will
                            use the old price for invoicing purposes as the price has already been
                            agreed to by both parties.
                          </Text>
                        )}
                        <div className='mt-4 flex items-center'>
                          <Input
                            className='h-5 w-5 border-2 border-primary-blue-100 rounded mr-2 text-dark-green cursor-pointer'
                            type='checkbox'
                            checked={values.subsidised}
                            onChange={() => {
                              setFieldValue('subsidised', !values.subsidised);
                            }}
                          />
                          <Text
                            className='mr-2 select-none cursor-pointer'
                            onClick={() => {
                              setFieldValue('subsidised', !values.subsidised);
                            }}
                          >
                            Subsidised with a condition
                          </Text>
                        </div>

                        <div
                          className='mt-4'
                          style={{
                            display: values.subsidised ? 'block' : 'none',
                          }}
                        >
                          <Heading className='text-dark-blue mb-2' as='h5'>
                            Conditions
                          </Heading>
                          <Input
                            name='conditions'
                            type='text'
                            className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                            placeholder='What are your conditions? (max 50 characters)'
                            value={values.conditions}
                            maxLength={50}
                            onChange={(e) => setFieldValue('conditions', e.target.value)}
                            onBlur={(e) => {
                              setFieldValue('conditions', e.target.value.trim());
                            }}
                          />
                          {errors.conditions && (
                            <div className='text-red text-base'>{errors.conditions}</div>
                          )}
                        </div>

                        <div className='flex w-full mt-4'>
                          <div>
                            <Button
                              variant='primary'
                              modifiers={'inverted'}
                              type='button'
                              onClick={() => {
                                setServiceModalOpen(false);
                                setEditingModal(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className='ml-auto'>
                            <Button
                              variant='primary'
                              data-test-id='complete-account-type'
                              type='submit'
                            >
                              {editingModal ? 'Save' : 'Add Service'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  })
);
export default ServicesComponent;
