// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Button, Form, FormFeedback, Label, Input } from 'reactstrap';
import Select from 'react-select';

import { visibilityOptions, typeOptions } from './options';
import { QuestionGrid, CustomFormGroup, CustomCreatableSelect } from './styles';

const schema = Yup.object().shape({
  question: Yup.string().required('Required'),
  questionType: Yup.string().required('Required'),
  questionVisibility: Yup.string().required('Required'),
  answersMandatory: Yup.boolean().default(false),
  potentialAnswers: Yup.array(Yup.string()).when('answersMandatory', {
    is: true,
    then: Yup.array(Yup.string()).required().min(1, 'Required'),
  }),
  order: Yup.number(),
});

type Props = {
  onComplete: Function;
};

const defaultFormState = {
  question: '',
  questionType: '',
  questionVisibility: '',
  answersMandatory: false,
};

@inject('screening')
@observer
class ScreeningQuestionForm extends Component<Props> {
  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  render() {
    const { screening, formState = {}, onComplete = () => {}, mode = 'create' } = this.props;
    const successMessage = mode === 'create' ? 'Question Added' : 'Question Updated';
    const submit = mode === 'create' ? screening.addQuestion : screening.editQuestion;

    const initialValues = {
      ...defaultFormState,
      ...formState,
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          submit(values).then((response) => {
            if (!response.hasError) {
              onComplete();
              toast.success(successMessage);
            } else {
              response.errors.forEach((err) => {
                const fieldName = err.field.replace(/questions\[\d+\]\./g, '');
                actions.setFieldTouched(fieldName, true, false);
                actions.setFieldError(fieldName, err.defaultMessage);
              });

              actions.setStatus({ message: response.message });
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setTouched,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <QuestionGrid>
                <CustomFormGroup gridArea='question'>
                  <Label>Question</Label>
                  <Input
                    bsSize='lg'
                    name='question'
                    value={values.question}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.question && !!errors.question}
                  />
                  {touched.question && errors.question && (
                    <FormFeedback className='d-block text-danger'>{errors.question}</FormFeedback>
                  )}
                </CustomFormGroup>
                <CustomFormGroup gridArea='showWhen'>
                  <Label>Show Question For</Label>
                  <Select
                    name='questionVisibility'
                    options={visibilityOptions}
                    value={values.qVisibility}
                    onChange={(select) => {
                      setFieldValue(`questionVisibility`, select.value);
                      setFieldValue(`qVisibility`, select);
                    }}
                    onBlur={() => setTouched({ ...touched, questionVisibility: true })}
                    invalid={touched.questionVisibility && !!errors.questionVisibility}
                  />
                  {touched.questionVisibility && errors.questionVisibility && (
                    <FormFeedback className='d-block text-danger'>
                      {errors.questionVisibility}
                    </FormFeedback>
                  )}
                </CustomFormGroup>
                <CustomFormGroup gridArea='questionType'>
                  <Label>Question Type</Label>
                  <Select
                    name={`questionType`}
                    options={typeOptions}
                    value={values.qType}
                    onChange={(select) => {
                      setFieldValue(`qType`, select);

                      const value = select.value;
                      setFieldValue(`questionType`, value);
                      if (value === 'Multichoice' || value === 'Checkbox') {
                        setFieldValue(`answersMandatory`, true);
                      } else {
                        setFieldValue(`answersMandatory`, false);
                      }
                    }}
                    onBlur={() => setTouched({ ...touched, questionType: true })}
                    invalid={touched.questionType && !!errors.questionType}
                  />
                  {touched.questionType && errors.questionType && (
                    <FormFeedback className='d-block text-danger'>
                      {errors.questionType}
                    </FormFeedback>
                  )}
                  {values.answersMandatory && (
                    <>
                      <CustomCreatableSelect
                        name='potentialAnswers'
                        placeholder='Enter Options Here'
                        value={values.pAnswers}
                        isClearable
                        isMulti
                        onChange={(options) => {
                          options.forEach((o) => (o.value = o.value.replace("'", ''))); // Quick & dirty fix for EN-1141
                          setFieldValue(`pAnswers`, options);
                          const valueArr = options.map((o) => o.value);
                          setFieldValue(`potentialAnswers`, valueArr);
                        }}
                        onBlur={() =>
                          setTouched({
                            ...touched,
                            potentialAnswers: true,
                          })
                        }
                        invalid={touched.potentialAnswers && !!errors.potentialAnswers}
                      />
                      {touched.potentialAnswers && errors.potentialAnswers && (
                        <FormFeedback className='d-block text-danger'>
                          {errors.potentialAnswers}
                        </FormFeedback>
                      )}
                    </>
                  )}
                </CustomFormGroup>
              </QuestionGrid>

              {this.getSubmitError(status)}

              <div className='text-center mt-3'>
                <Button
                  color='primary'
                  size='lg'
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {mode === 'create' ? 'Create Question' : 'Update Question'}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default ScreeningQuestionForm;
