import * as React from 'react';
import { observer, inject } from 'mobx-react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Tab, TabGroup, TabList } from '@headlessui/react';
import { Settings } from 'react-feather';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import Plus from 'jsx:assets/svg/plus.svg';
import VectorBlue from './Vector-blue.png';
import Calendar from './Calendar';
import ScheduleService from 'services/ScheduleService';
import {
  GetTimeDropDown,
  StyledCard,
  ListIcon,
  LocationPinIcon,
  BinIcon,
  ColouredInput,
  ViewTabButton,
} from './styles';
import { DropdownColourStyles } from '../SetupWizard/WizardForm/styles';
import { days, CheckboxBooleans, PopoverCheckboxState, weeklyHoursInitialState } from './data';
import CalendarModal from './CalendarModal';
import InstructionModal from './instruction_modal';
import { BlockLoading } from '../../../common/Loading';
import { advancedSettings } from './CalendarModal';
import Popover from '../../../shared-ui/Popover';

const moment = extendMoment(Moment);

const DeepClone = (input) => {
  return JSON.parse(JSON.stringify(input));
};

const ManageAvailabilityComponent = inject(
  'auth',
  'schedule',
  'prices',
  'locations'
)(
  observer((props) => {
    const [isCalendarView, setIsCalendarView] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [serviceModalOpen, setServiceModalOpen] = React.useState(false);
    const [serviceArray, setServiceArray] = React.useState([]);
    const [locationArray, setLocationArray] = React.useState([]);
    const [currentModel, setCurrentModel] = React.useState(null);
    const [initialState, setInitialState] = React.useState([]);
    const [unfinishedChanges, setUnfinishedChanges] = React.useState(false);
    const [instructionModalOpen, setInstructionModalOpen] = React.useState(false);

    const toggleInstructionModal = () => {
      setInstructionModalOpen(!instructionModalOpen);
    };

    const changeModal = (modalOpen) => {
      setServiceModalOpen(modalOpen);
      if (!modalOpen) {
        setCurrentModel(null);
      }
    };

    // If an error is encountered while loading data, display a message to the user
    const onLoadError = () => {
      toast.error('Connection issue, try reloading the page');
      setIsLoading(false);
    };

    React.useEffect(() => {
      props.prices.getPrices().then((services) => {
        if (services.data == null) {
          onLoadError();
          return;
        }
        setServiceArray(services.data.responseObject);
      });

      props.locations.getLocations().then((locations) => {
        if (locations.data == null) {
          onLoadError();
          return;
        }
        setLocationArray(locations.data.responseObject);
      });

      let initialTimes = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      };
      let initialChecked = {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      };

      ScheduleService.getSchedule().then((res) => {
        if (res.data == null) {
          onLoadError();
          return;
        }

        if (res.data.template.length === 0) {
          setInstructionModalOpen(true);
        }
        res.data.template.forEach((times) => {
          initialChecked[times.templateDay] = true;
          const obj = {
            startTime: {
              label: moment(times.startTime, ['HH.mm']).format('hh:mm a'),
              value: times.startTime,
            },
            endTime: {
              label: moment(times.endTime, ['HH.mm']).format('hh:mm a'),
              value: times.endTime,
            },
            prices: times.chargeIds,
            locations: times.locationIds,
            advancedSettings: [],
          };

          advancedSettings.forEach((setting) => {
            if (times[setting.value]) {
              obj.advancedSettings.push(setting.label);
            }
          });

          initialTimes[times.templateDay] = initialTimes[times.templateDay].concat(obj);
        });

        setWeeklyHours(initialTimes);
        const clone = JSON.parse(JSON.stringify(initialTimes));
        setInitialState(clone);
        setUnfinishedChanges(false);
        setDaysEnabled(initialChecked);
        setIsLoading(false);
      });
    }, []);

    //record the state of the checkboxes that enable the day
    const [daysEnabled, setDaysEnabled] = React.useState(CheckboxBooleans);

    const [errors, setErrors] = React.useState({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    });

    //record the state of the checkboxes that copy times from one day to another
    const [copyDaysEnabled, setCopyDaysEnabled] = React.useState(
      props.copyDaysEnabled ? props.copyDaysEnabled : PopoverCheckboxState
    );

    const toogleDay = (day) => {
      //when toogle on and times array is empty, add the defaultTimeObj
      if (!daysEnabled[day]) {
        if (weeklyHours[day].length === 0) {
          const spreadState = DeepClone(weeklyHours);
          spreadState[day] = spreadState[day].concat(defaultTimeObj);
          setWeeklyHours(spreadState);
        }
      }

      setDaysEnabled({
        ...daysEnabled,
        [day]: !daysEnabled[day],
      });
    };

    //keep track of the times specified for availability
    var [weeklyHours, setWeeklyHours] = React.useState(
      props.weeklyHours ? props.weeklyHours : weeklyHoursInitialState
    );

    //calendar time array
    var [calendarTimes, setCalendarTimes] = React.useState(
      props.calendarTimes ? props.calendarTimes : []
    );

    const defaultTimeObj = {
      startTime: { label: '9:00am', value: '09:00:00' },
      endTime: { label: '5:00pm', value: '17:00:00' },
      prices: serviceArray.map((service) => service.id),
      locations: locationArray.map((locations) => locations.location.id),
      advancedSettings: ['On the hour', 'Half past the hour'],
    };

    //when the plus button is clicked, add a time slot
    const addTimeSlot = (weekday, timeObj = {}) => {
      const newSlot = { ...defaultTimeObj, ...timeObj };

      var placeholder = DeepClone(weeklyHours);
      placeholder[weekday] = weeklyHours[weekday].concat(newSlot);

      setWeeklyHours(placeholder);

      let daysPlaceholder = { ...daysEnabled };

      daysPlaceholder[weekday] = true;
      setDaysEnabled(daysPlaceholder);
    };

    React.useEffect(() => {
      let placeholder = { ...errors };

      days.forEach((day) => {
        let output = false;
        for (let i = 0; i < weeklyHours[day.name].length; i += 1) {
          for (let j = 0; i < weeklyHours[day.name].length; i += 1) {
            //check if time order is valid
            if (
              !moment(moment(weeklyHours[day.name][i].startTime.value, 'h:mma')).isBefore(
                moment(weeklyHours[day.name][i].endTime.value, 'h:mma'),
                'minute'
              )
            ) {
              output = true;
              break;
            }

            //check if times are overlapping
            if (i !== j) {
              const range = moment.range(
                moment(weeklyHours[day.name][i].startTime.value, 'h:mma'),
                moment(weeklyHours[day.name][i].endTime.value, 'h:mma')
              );

              const secondRange = moment.range(
                moment(weeklyHours[day.name][j].startTime.value, 'h:mma'),
                moment(weeklyHours[day.name][j].endTime.value, 'h:mma')
              );

              if (range.overlaps(secondRange, { adjacent: false })) {
                output = true;
                break;
              }
            }
          }
        }
        placeholder[day.name] = output;
      });

      setErrors(placeholder);
    }, [weeklyHours]);

    //update the time in the weeklyHours array when the drop down is changed
    const updateTime = (option, day, index, time) => {
      var placeholder = DeepClone(weeklyHours);
      placeholder[day][index][time] = option;
      setWeeklyHours(placeholder);
    };

    const deleteTime = (day, index) => {
      var placeholder = JSON.parse(JSON.stringify(weeklyHours));
      placeholder[day].splice(index, 1);

      if (placeholder[day].length === 0) {
        toogleDay(day);
      }
      setWeeklyHours(placeholder);
    };

    const selectTimes = (currentDay, index) => {
      //changing the boolean/checkboxes in the pop up
      var daySelect = copyDaysEnabled;
      daySelect[0][currentDay][index].checked = !daySelect[0][currentDay][index].checked;

      setCopyDaysEnabled(daySelect);
    };

    //copy times from one day to another
    const copyTimes = (currentDay) => {
      var placeholder = DeepClone(weeklyHours);
      let daysPlaceholder = { ...daysEnabled };

      copyDaysEnabled[0][currentDay].forEach((target) => {
        if (target.checked) {
          placeholder[target.day] = placeholder[currentDay];

          if (daysEnabled[currentDay]) {
            daysPlaceholder[target.day] = true;
          } else {
            daysPlaceholder[target.day] = false;
          }
        }
      });
      setWeeklyHours(placeholder);
      setDaysEnabled(daysPlaceholder);
    };

    //set an object that is then passed onto the react big calendar

    React.useEffect(() => {
      var tempCalendarTimes = [];
      var hasChangesObject = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      };

      days.forEach((day) => {
        if (daysEnabled[day.name]) {
          //check if there are changes made

          hasChangesObject[day.name] = hasChangesObject[day.name].concat(weeklyHours[day.name]);

          weeklyHours[day.name].forEach((times) => {
            const calendarObject = {
              start: moment(new Date(`2022/01/${day.date} ${times['startTime'].value}`)).toDate(),
              end: moment(new Date(`2022/01/${day.date} ${times['endTime'].value}`)).toDate(),
            };

            tempCalendarTimes = tempCalendarTimes.concat(calendarObject);
          });
        }
      });

      if (JSON.stringify(hasChangesObject) === JSON.stringify(initialState)) {
        setUnfinishedChanges(false);
      } else {
        setUnfinishedChanges(true);
      }

      setCalendarTimes(tempCalendarTimes);
    }, [weeklyHours, daysEnabled]);

    const isValid = React.useMemo(() => {
      let valid = false;
      for (const [key, value] of Object.entries(weeklyHours)) {
        if (value.length !== 0 && daysEnabled[key] === true) {
          valid = true;
          break;
        }
      }

      let noErrors = true;

      for (const [key, value] of Object.entries(errors)) {
        if (key && value) {
          noErrors = false;
        }
      }
      return valid && noErrors;
    }, [weeklyHours, daysEnabled, errors]);

    const onSubmit = React.useCallback(async () => {
      if (isValid) {
        setIsLoading(true);
        setUnfinishedChanges(false);

        await publishImmediately();

        toast.success('Schedule published');
        setIsLoading(false);
      }
    }, [weeklyHours, daysEnabled, copyDaysEnabled, errors, isValid]);

    const publishImmediately = async () => {
      const data = formatData();
      const { schedule } = props;
      await schedule.publishSchedule({
        immediate: true,
        template: data,
      });
    };

    const formatData = () => {
      let timesArray = [];
      if (!weeklyHours) {
        return null;
      }

      days.forEach((day) => {
        if (daysEnabled[day.name]) {
          weeklyHours[day.name].forEach((times) => {
            const startMoment = moment(`${day.name} ${times.startTime.value}`, 'dddd HH:mm:ss');
            const endMoment = moment(`${day.name} ${times.endTime.value}`, 'dddd HH:mm:ss');

            const defaultTime = {
              id: 4655,
              templateDay: day.name,
              startTime: times.startTime.value,
              endTime: times.endTime.value,
              start: startMoment.toDate(),
              end: endMoment.toDate(),
              startOption: {
                label: startMoment.format('h:mma'),
                value: startMoment,
              },
              endOption: {
                label: endMoment.format('h:mma'),
                value: endMoment,
              },
              durationMin: moment(times.endTime.value, 'h:mma').diff(
                moment(times.startTime.value, 'h:mma'),
                'minutes'
              ),
              location: null,
              locationIds: times.locations,
              chargeIds: times.prices,
              allowOnHour: times.advancedSettings.includes('On the hour'),
              allowOnHalfHour: times.advancedSettings.includes('Half past the hour'),
              allowOnQuarterToHour: times.advancedSettings.includes('Quarter to the hour'),
              allowOnQuarterPastHour: times.advancedSettings.includes('Quarter past the hour'),
            };

            timesArray = timesArray.concat(defaultTime);
          });
        }
      });

      return timesArray;
    };

    function getTime(day, index, time, colour) {
      const start = moment().hours(0).minutes(0).seconds(0);
      const end = moment().hours(23).minutes(45).seconds(0);
      const range = moment.range(start._d, end._d);
      const timeRageArr = Array.from(range.by('minutes', { step: 15 }));

      const options = timeRageArr.map((time) => {
        return {
          label: moment(time).format('hh:mma'),
          value: moment(time).format('HH:mm:ss'),
        };
      });

      const selectedTime = options.find((v) => v.value === weeklyHours[day][index][time].value);

      return (
        <>
          <GetTimeDropDown
            colour={colour}
            styles={DropdownColourStyles}
            name='concerns'
            className='bg-white w-full rounded-md'
            placeholder=''
            isSearchable={true}
            value={selectedTime}
            options={options}
            onChange={(option) => updateTime(option, day, index, time)}
          />
        </>
      );
    }

    function Tabs() {
      return (
        <div className='w-full max-w-md relative'>
          <TabGroup defaultIndex={0}>
            <TabList className='flex relative border-0 justify-center sm:justify-end'>
              <Tab as={React.Fragment}>
                {({ selected }) => (
                  <ViewTabButton
                    className='rounded-l-md'
                    selected={selected}
                    onClick={() => setIsCalendarView(false)}
                    style={{ width: '42%' }}
                  >
                    <div className='flex flex-row items-center m-auto justify-between'>
                      <ListIcon colour={selected ? 'white' : '#4987CE'} />
                      <Text
                        className={`${
                          selected ? 'text-white' : 'text-primary-blue-100'
                        } font-semibold`}
                      >
                        List View
                      </Text>
                    </div>
                  </ViewTabButton>
                )}
              </Tab>
              <Tab as={React.Fragment}>
                {({ selected }) => (
                  <ViewTabButton
                    className='rounded-r-md'
                    selected={selected}
                    onClick={() => setIsCalendarView(true)}
                    style={{ width: '53%' }}
                  >
                    <div className='flex flex-row items-center m-auto justify-between'>
                      <LocationPinIcon colour={selected ? 'white' : '#4987CE'} />
                      <Text
                        className={`${
                          selected ? 'text-white' : 'text-primary-blue-100'
                        } font-semibold`}
                      >
                        Calendar View
                      </Text>
                    </div>
                  </ViewTabButton>
                )}
              </Tab>
            </TabList>
          </TabGroup>
        </div>
      );
    }

    function ShowTimesModal(day) {
      return (
        <Popover
          CustomButton={
            <div className='focus:outline-none border-0 bg-transparent mt-2'>
              <img src={VectorBlue} alt='' className='fill-current cursor-pointer outline-none' />
            </div>
          }
          CustomPopover={
            <>
              <div className='z-10 absolute right-0'>
                <StyledCard style={{ width: '205px' }}>
                  <div className='w-full pb-2 flex flex-col'>
                    <Text className='text-left text-primary-blue-50' style={{ fontSize: '13px' }}>
                      COPY TIMES TO...
                    </Text>
                    {days.map((currentDay, index) => (
                      <div className='py-1 grid grid-cols-5 my-1' key={currentDay.name}>
                        <div className='col-span-4'>
                          <Heading className='text-left text-dark-blue' as={'h5'}>
                            {currentDay.name}
                          </Heading>
                        </div>
                        <div className='col-span-1'>
                          <ColouredInput
                            defaultChecked={copyDaysEnabled[0][day.day][index].checked}
                            type='checkbox'
                            className='w-5 h-5 form-checkbox'
                            onClick={() => selectTimes(day.day, index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant='primary'
                    className='mx-2 mb-2 bg-white group'
                    onClick={() => copyTimes(day.day)}
                    style={{ width: 'auto', minWidth: '0' }}
                  >
                    <Text
                      modifiers='bold'
                      className='text-primary-blue-100 group-hover:text-dark-blue'
                    >
                      Apply
                    </Text>
                  </Button>
                </StyledCard>
              </div>
            </>
          }
        />
      );
    }

    function CheckOverlap(times, day, includeSelf = true) {
      const range = moment.range(
        moment(times.startTime.value, 'h:mma'),
        moment(times.endTime.value, 'h:mma')
      );

      var placeholder = weeklyHours;
      // if (placeholder[day].length <= 1) {
      //   return false;
      // }

      let counter = 0;
      let output = false;

      placeholder[day].forEach((timeSlot) => {
        const tempRange = moment.range(
          moment(timeSlot.startTime.value, 'h:mma'),
          moment(timeSlot.endTime.value, 'h:mma')
        );
        if (range.overlaps(tempRange, { adjacent: false }) && !range.isSame(tempRange)) {
          output = true;
        }
        if (range.isSame(tempRange)) {
          counter++;
        }
      });

      if (counter > 1 || (counter === 1 && !includeSelf)) {
        output = true;
      }
      return output;
    }

    function renderTimeSlots(day) {
      return (
        <>
          {!daysEnabled[day.name] ? (
            <Text className='hidden sm:block text-left text-grey-200'>Unavailable</Text>
          ) : (
            weeklyHours[day.name].map((number, index) => {
              const overlap = CheckOverlap(number, day.name);
              const validTime = !moment(moment(number.startTime.value, 'h:mma')).isBefore(
                moment(number.endTime.value, 'h:mma'),
                'minute'
              );
              return (
                <div className='flex flex-col mb-4 last:mb-0' key={index}>
                  <div className='flex flex-row items-center'>
                    <div className='w-20'>
                      {getTime(
                        day.name,
                        index,
                        'startTime',
                        overlap || validTime ? 'red' : '#4987CE'
                      )}
                    </div>
                    <Text className='mx-1 text-[#676767]'>-</Text>
                    <div className='w-20'>
                      {getTime(
                        day.name,
                        index,
                        'endTime',
                        overlap || validTime ? 'red' : '#4987CE'
                      )}
                    </div>
                    <Settings
                      size={20}
                      className='align-middle mx-3 cursor-pointer'
                      onClick={() => {
                        setServiceModalOpen(true);
                        setCurrentModel({
                          number: number,
                          index: index,
                          day: day,
                        });
                      }}
                    />

                    <div>
                      <BinIcon
                        className='fill-current cursor-pointer outline-hidden'
                        onClick={() => deleteTime(day.name, index)}
                      />
                    </div>
                  </div>
                  <div className='text-left ml-2'>
                    {validTime ? (
                      <Text className='text-red-500 mb-2'>Please enter a valid time period</Text>
                    ) : null}
                    {overlap ? (
                      <Text className='text-red-500 mb-2'>
                        Times overlap with another set of times
                      </Text>
                    ) : null}
                  </div>
                </div>
              );
            })
          )}
        </>
      );
    }

    const FooterComponent = props.footer;

    return (
      <>
        <div className={`w-full m-auto ${isCalendarView ? 'max-w-screen-lg' : 'max-w-screen-md'}`}>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-lg text-center'>
              <Heading className='text-dark-blue mt-4 mb-2' as='h4'>
                Set your availability rules
              </Heading>
              <Text className='mb-6'>
                This will ensure clients can book your services at times that suit you.
              </Text>
            </div>
            <div className='w-full'>
              <StyledCard className='p-6'>
                <div className='w-full flex items-center mb-2 justify-end'>
                  <div className='' style={{ width: '300px' }}>
                    {Tabs()}
                  </div>
                </div>
                <hr className='border-primary-blue-100 mx-auto w-full opacity-100' />
                <Heading className='text-dark-blue mt-2 mb-6 text-left' as='h5'>
                  Set your weekly hours
                </Heading>
                {isLoading ? <BlockLoading /> : null}
                {!isCalendarView ? (
                  <>
                    <div className='my-2'>
                      {days.map((day, firstIndex) => (
                        <div key={day.name}>
                          <div className='flex justify-between md:min-h-[45px]'>
                            <div className='flex flex-col md:flex-row items-center'>
                              <div className='flex mr-4'>
                                <label className='mr-4'>
                                  <ColouredInput
                                    checked={daysEnabled[day.name]}
                                    type='checkbox'
                                    className='w-5 h-5 relative top-px'
                                    onChange={() => toogleDay(day.name)}
                                  />
                                </label>
                                <Heading
                                  className='text-dark-blue text-left w-12 cursor-pointer'
                                  as={'h5'}
                                  onClick={() => toogleDay(day.name)}
                                >
                                  {day.day}
                                </Heading>
                              </div>
                              <div className='mt-0 ml-0 hidden md:block'>
                                {renderTimeSlots(day)}
                              </div>
                            </div>
                            <div className='flex md:mr-4 items-center'>
                              <Plus
                                alt='Add time slot'
                                className='cursor-pointer mr-2'
                                onClick={() => addTimeSlot(day.name)}
                              />
                              <ShowTimesModal day={day.name} />
                            </div>
                          </div>
                          <div className='block md:hidden sm:ml-11 mt-2'>
                            {renderTimeSlots(day)}
                          </div>
                          {day.day !== 'SUN' ? (
                            <hr className='border-primary-blue-100 mx-auto w-full opacity-100' />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div id='js-availabilityCalendarCard' className='m-auto w-full p-4'>
                    <Calendar
                      times={calendarTimes}
                      setServiceModalOpen={changeModal}
                      addTimeSlot={addTimeSlot}
                      weeklyHours={weeklyHours}
                      setCurrentModel={setCurrentModel}
                      checkForOverlaps={CheckOverlap}
                    />
                  </div>
                )}
              </StyledCard>
            </div>
            <FooterComponent onSubmit={onSubmit} isValid={isValid} isLoading={isLoading} />
          </div>
        </div>
        <Prompt
          when={unfinishedChanges}
          message='You have unpublished changes, are you sure you want to leave the page?'
        />

        <InstructionModal isModalOpen={instructionModalOpen} toggleModal={toggleInstructionModal} />

        {currentModel !== null ? (
          <CalendarModal
            serviceModalOpen={serviceModalOpen}
            setServiceModalOpen={changeModal}
            currentModel={currentModel}
            serviceArray={serviceArray}
            locationArray={locationArray}
            weeklyHours={weeklyHours}
            setWeeklyHours={setWeeklyHours}
            deleteTime={deleteTime}
          />
        ) : null}
      </>
    );
  })
);

export default ManageAvailabilityComponent;
