import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';

import { Loading } from 'common';
import { FooterNav } from '../WizardForm/styles';
import InvoiceForm from '../../../../modules/auth/components/InvoiceSettingsForm/InvoiceForm';
import { WizardFormContext } from '../index';
import { defaultFormState } from '../../../../modules/auth/components/InvoiceSettingsForm';

type InvoicingStepProps = {
  auth: any;
  invoices: any;
};

const InvoicingStep: React.FC<InvoicingStepProps> = ({ invoices, auth }) => {
  let formRef = React.useRef(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [initialFormState, setInitialFormState] = React.useState();
  const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  // Check current invoice settings and set initial form data
  React.useEffect(() => {
    invoices.getSettings().then((resp) => {
      setLoading(false);
      const {
        taxEnabled,
        taxNumber,
        pricesIncludeTax,
        bankAccountNumber,
        daysUntilInvoiceDue,
        invoiceFrom,
        invoiceFooter,
      } = resp.data;

      Object.keys(invoiceFrom).forEach((k) => invoiceFrom[k] == null && delete invoiceFrom[k]);

      const formState = {
        ...{ taxEnabled },
        ...(taxEnabled && taxNumber && { taxNumber }),
        ...{ pricesIncludeTax },
        ...(bankAccountNumber && { bankAccountNumber }),
        ...(daysUntilInvoiceDue && { daysUntilInvoiceDue }),
        ...(invoiceFooter && { invoiceFooter }),
        ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 7 && { dueDate1Week: true }),
        ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 14 && { dueDate2Week: true }),
        ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 21 && { dueDate3Week: true }),
        ...(daysUntilInvoiceDue && daysUntilInvoiceDue === 28 && { dueDate4Week: true }),
        invoiceFrom,
        country: auth.currentPractitioner.country,
      };

      const mergedFormState = {
        ...defaultFormState,
        ...formState,
        invoiceFrom: {
          ...defaultFormState.invoiceFrom,
          ...formState.invoiceFrom,
        },
      };

      setInitialFormState(mergedFormState);
    });
  }, []);

  const handleSubmitCustom = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmit = async (values, actions) => {
    setIsSubmitting(true);

    const {
      taxEnabled,
      taxNumber,
      pricesIncludeTax,
      bankAccountNumber,
      daysUntilInvoiceDue,
      invoiceFrom,
      invoiceFooter,
    } = values;

    Object.keys(invoiceFrom).forEach((k) => invoiceFrom[k] == '' && delete invoiceFrom[k]);

    const data = {
      taxEnabled,
      taxNumber,
      pricesIncludeTax,
      bankAccountNumber:
        auth.currentPractitioner.country === 'AUS'
          ? bankAccountNumber.replace('-', '')
          : bankAccountNumber,
      daysUntilInvoiceDue,
      invoiceFrom,
      invoiceFooter,
    };

    invoices.setSettings(data).then((response) => {
      if (!response.hasError) {
        setDisabled(true);
        onNext();
      } else {
        response.errors.forEach((err) => {
          actions.setFieldTouched(err.field, true, false);
          actions.setFieldError(err.field, err.defaultMessage);
        });
        actions.setStatus({ message: response.message });
        toast.error(response.message);
      }
      actions.setSubmitting(false);
      setIsSubmitting(false);
    });
  };

  const goBack = async () => {
    await onPrevious();
  };

  if (loading || initialFormState == null) {
    return <Loading />;
  }

  return (
    <div className='md:w-3/4 lg:w-3/5 xl:w-6/12 xxl:w-5/12 xxxl:w-4/12 m-auto pb-10 xs:px-4 sm:px-4'>
      <Heading className='text-dark-blue mt-4 mb-2' as='h4'>
        Set your invoicing details
      </Heading>
      <Text className='mb-6 whitespace-pre'>
        {`This will ensure your invoices are automatically populated with the following details.`}
      </Text>
      <div className='flex flex-col w-full items-center text-left'>
        <div className='bg-white p-8 rounded-md mb-4'>
          <InvoiceForm
            initialFormState={initialFormState}
            onSubmit={onSubmit}
            formRef={formRef}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </div>
        <FooterNav>
          <div className='flex justify-start'>
            {currentStep > 0 && (
              <Button variant='primary' modifiers={'inverted'} onClick={goBack} disabled={loading}>
                Back
              </Button>
            )}
          </div>
          <div className='flex justify-end'>
            <Button variant='primary' onClick={handleSubmitCustom} disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </FooterNav>
      </div>
    </div>
  );
};

export default withRouter(inject('auth', 'invoices')(observer(InvoicingStep)));
