import React from 'react';
import InputMask from 'react-input-mask';

// Temporary component to wrap InputMask library just in case
// we need to stop using it (not maintained)
// Check old code in PR https://github.com/clearhead-ltd/practitioner-portal/pull/370/files
// for an example of how this can be implemented without library

const BankAccountField = ({ country, ...other }: any) => {
  // Set the mask based on country
  let mask = '';
  let placeholder = '';
  if (country === 'AUS') {
    mask = '999999-999999999';
    placeholder = 'BSB-Account Number';
  } else {
    mask = '99-9999-9999999-999'; // NZ bank account format
    placeholder = 'Account Number';
  }

  return (
    <InputMask
      {...other}
      mask={mask}
      maskChar={null}
      placeholder={placeholder}
      className='form-control-lg form-control'
    />
  );
};

export default BankAccountField;
